/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/* INCLUDE FONTAWESOME PRO */
@import "./assets/fontawesome/css/all.min.css";
.text-center{
    text-align: center !important;
}
.text-white{
    color:white !important;
}
body{
    background: #c1e9f5 !important;
  }
  img.logotop {
    margin: 0px auto;
    display: block;
}
ion-title.md.title-default.hydrated ion-icon.md.hydrated {
    position: absolute;
    top: 14px;
}
ion-toolbar.toolbar-title-default.md.in-toolbar.hydrated {
    --background: #c1e9f5;
}
.password-error{color: red;}
h3{
    color: #333;
}
ion-button{
    --background:#07A8E4;
}
ion-select{
    width: 100%;
}
.bgComplete h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    color: #27AAE1;
    text-transform: uppercase;
}
.bgComplete {
    background: url('./assets/bgcomplete.png');
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    right: 0;
    margin: 0 auto;
    align-items: stretch;
    justify-content: center;
    flex-direction: column;
}
/*   https://fonts.google.com/specimen/MuseoModerno?query=muse   Global Font*/