// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /**  Cotg primary **/
  --ion-color-cotg-primary: #91E0F8;
  --ion-color-cotg-primary-rgb: 145,224,248;
  --ion-color-cotg-primary-contrast: #000000;
  --ion-color-cotg-primary-contrast-rgb: 0,0,0;
  --ion-color-cotg-primary-shade: #80c5da;
  --ion-color-cotg-primary-tint: #9ce3f9;

  /**  Cotg secondary **/
  --ion-color-cotg-secondary: #76C070;
  --ion-color-cotg-secondary-rgb: 118,192,112;
  --ion-color-cotg-secondary-contrast: #000000;
  --ion-color-cotg-secondary-contrast-rgb: 0,0,0;
  --ion-color-cotg-secondary-shade: #68a963;
  --ion-color-cotg-secondary-tint: #84c67e;

  /**  Cotg tertiary **/
  --ion-color-cotg-tertiary: #FF4F4F;
  --ion-color-cotg-tertiary-rgb: 255,79,79;
  --ion-color-cotg-tertiary-contrast: #000000;
  --ion-color-cotg-tertiary-contrast-rgb: 0,0,0;
  --ion-color-cotg-tertiary-shade: #e04646;
  --ion-color-cotg-tertiary-tint: #ff6161;

  /**  Cotg quadratic **/
  --ion-color-cotg-quadratic: #FF7528;
  --ion-color-cotg-quadratic-rgb: 255,117,40;
  --ion-color-cotg-quadratic-contrast: #000000;
  --ion-color-cotg-quadratic-contrast-rgb: 0,0,0;
  --ion-color-cotg-quadratic-shade: #e06723;
  --ion-color-cotg-quadratic-tint: #ff833e;

  /**  Cotg Bg **/
  --ion-color-cotg-bg: #C1E9F5;
  --ion-color-cotg-bg-rgb: 193,233,245;
  --ion-color-cotg-bg-contrast: #000000;
  --ion-color-cotg-bg-contrast-rgb: 0,0,0;
  --ion-color-cotg-bg-shade: #aacdd8;
  --ion-color-cotg-bg-tint: #c7ebf6;

}

.ion-color-cotg-primary {
  --ion-color-base: var(--ion-color-cotg-primary);
  --ion-color-base-rgb: var(--ion-color-cotg-primary-rgb);
  --ion-color-contrast: var(--ion-color-cotg-primary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cotg-primary-contrast-rgb);
  --ion-color-shade: var(--ion-color-cotg-primary-shade);
  --ion-color-tint: var(--ion-color-cotg-primary-tint);
}

.ion-color-cotg-secondary {
  --ion-color-base: var(--ion-color-cotg-secondary);
  --ion-color-base-rgb: var(--ion-color-cotg-secondary-rgb);
  --ion-color-contrast: var(--ion-color-cotg-secondary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cotg-secondary-contrast-rgb);
  --ion-color-shade: var(--ion-color-cotg-secondary-shade);
  --ion-color-tint: var(--ion-color-cotg-secondary-tint);
}

.ion-color-cotg-tertiary {
  --ion-color-base: var(--ion-color-cotg-tertiary);
  --ion-color-base-rgb: var(--ion-color-cotg-tertiary-rgb);
  --ion-color-contrast: var(--ion-color-cotg-tertiary-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cotg-tertiary-contrast-rgb);
  --ion-color-shade: var(--ion-color-cotg-tertiary-shade);
  --ion-color-tint: var(--ion-color-cotg-tertiary-tint);
}

.ion-color-cotg-quadratic {
  --ion-color-base: var(--ion-color-cotg-quadratic);
  --ion-color-base-rgb: var(--ion-color-cotg-quadratic-rgb);
  --ion-color-contrast: var(--ion-color-cotg-quadratic-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cotg-quadratic-contrast-rgb);
  --ion-color-shade: var(--ion-color-cotg-quadratic-shade);
  --ion-color-tint: var(--ion-color-cotg-quadratic-tint);
}

.ion-color-cotg-bg {
  --ion-color-base: var(--ion-color-cotg-bg);
  --ion-color-base-rgb: var(--ion-color-cotg-bg-rgb);
  --ion-color-contrast: var(--ion-color-cotg-bg-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cotg-bg-contrast-rgb);
  --ion-color-shade: var(--ion-color-cotg-bg-shade);
  --ion-color-tint: var(--ion-color-cotg-bg-tint);
}
