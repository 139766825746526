/** Import SCSS Files here **/

@import "join-event"; //Guest Join Event Screens
@import "cotg-form";

@font-face {
  font-family: "SF-Pro";
  src: url(../assets/fonts/FontsFree-Net-SFProDisplay-Regular.ttf)
    format("truetype");
}

$homepage-button-border-color: #8ba5acd6; 
$trans-white: #ffffffd6;

/**
* Default Bg For Content
**/
.cotg-default-bg {
  background: var(--ion-color-cotg-bg);
}

/**
* Cotg header Styles to Reuse and change on Single Place.
**/
.cotg-header{
	.cotg-toolbar{
		--background: var(--ion-color-cotg-bg);
		--border-color: white;
	}
	.toolbar-noborder{
		--border-width: 0px !important;
	}
	.toolbar-btn-white{
		--color: white;
	}
}

.app-title {
	font-family: 'SF-Pro';
	font-size: 1.5rem;
	font-weight: 700;
}
.app-sub-title {
	font-family: "SF-Pro";
	font-size: 1.1rem;
	color: #393939;
}


ion-grid.ph {
	padding-left: 2em;
	padding-right: 2em;

	.app-button {
    width: 75%;
    font-weight: 700;
	}
}

ion-grid.app-grid {

	.app-sub-title {
    font-weight: bold;
    margin-top: 0;
	}

	h6 {
    margin-bottom: 0;
    font-weight: bold;
	}



  .btn_text {
    font-weight: 700;
  }
	.app-button {
    width: 75%;
    font-weight: 700;
		border: none;
		font-size: 1.2rem;
		margin-top: 15px;
		ion-icon {
			font-size: 1.3rem;
			margin-left: 2px;
		}
	}
	button {
    min-width: 188px;
    margin: 0 auto;
    width: 90%;
    min-height: 100px;
    background-color: #ffffffd6;
    border: solid 1px #{$homepage-button-border-color};
    padding-top: 30px;
    padding-bottom: 10px;
		border-radius: 6px;
  }
  .btn_icon {
    width: 50px;
    height: 50px;
    display: block;
    margin: 0 auto 10px;
    background-size: contain;
    &:focus {
      outline: 2px dashed #17171D;
    }
    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
    &::-moz-focus-inner {
      border: 0;
    }
    svg {
      outline: none;
      transition: transform 0.15s linear;
    }
  }

} 
 ion-toolbar{
	  .fad{
		  font-size: 1.9rem;
		  color: orange;
	  }
  }
.page {
	background-color: aqua;
	--ion-background:green;
}