/**
* Guest Screens Element Styles
**/
.join-event-container{
	display: flex;
  	height: 100%;
  	flex-direction: column;
  	align-items: center;

	.join-event-title {
	    display: flex;
	    width: 100%;
	    justify-content: center;
	    padding: 10px 0;
	    font-size: 15px;
	    line-height: 17.25px;
	    font-weight: 700;
	    text-transform: capitalize;
	    color: #636363;
	}
	.join-event-content{
		display: flex;
	    flex-direction: column;
	    justify-content: center;
	    align-items: center;
	    width: 100%;
	    height: 100%;

	    form { text-align: center; }

	    .form-field { margin: 35px 0 50px; }
	    .round{ border-radius: 30px; }
	    .cotg-input{
				    font-size: 14px;
	    }
	    // .label {
	    //     font-size: 18px;
	    //     font-weight: 900;
	    //     color: black;
	    //     text-transform: capitalize;
	    // }

	    .submit-btn {
	        --border-radius: 30px;
	        --padding-start: 2em;
	        --padding-end: 2em;
	        font-size: 18px;
	        font-weight: 900;
	        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	        min-width: 120px;
	    }
	}

}

